// import { Controller } from "@hotwired/stimulus";
// import { tns } from "../node_modules/tiny-slider/src/tiny-slider.js";

// export default class extends Controller {
//   static values = {
//     options: Object,
//   };

//   connect() {
//     this.slider = tns({
//       container: this.element,
//       ...this.defaultOptions(),
//       ...this.optionsValue,
//     });
//   }

//   defaultOptions() {
//     return {
//       items: 1,
//       slideBy: "page",
//       autoplay: true,
//       controls: true,
//       nav: true,
//       speed: 300,
//     };
//   }

//   disconnect() {
//     if (this.slider) {
//       this.slider.destroy();
//       this.slider = null;
//     }
//   }
// }