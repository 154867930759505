import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element)
  }
  scrollTo(event) {
    event.preventDefault();

    const targetId = event.currentTarget.dataset.target;
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const y = targetElement.getBoundingClientRect().top + window.scrollY - 100;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
}
